import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <select className="request-method">
                <option default>GET</option>
                <option>HEAD</option>
                <option>POST</option>
                <option>PUT</option>
                <option>DELETE</option>
                <option>CONNECT</option>
                <option>OPTIONS</option>
                <option>TRACE</option>
                <option>PATCH</option>
            </select> <input className="request-path" /> <button onClick={send}>Send</button>
            <hr />
            <pre className="http-response">
            </pre>
        </div>
    );
}

function send() {
    const apiHost = getApiHostName();
    const apiPath = document.querySelector( '.request-path' ).value;
    const method = document.querySelector( '.request-method' ).value
    const requestUrl = `https://${ apiHost }/echo/${ apiPath }`;

    fetch( requestUrl, {
        method
    } )
        .then( response => response.text() )
        .then( responseText => {
            const displayElement = document.querySelector( '.http-response' );
            displayElement.innerText = responseText;
        } );
}

function getApiHostName () {
    if ( window.location.hostname === 'localhost' ) {
        return 'qa.api.biw.cloud';
    }
    const envPrefix = getEnvPrefix();
    return envPrefix + 'api.biw.cloud';
}

function getEnvPrefix () {
    const hostParts = window.location.hostname.split( '.' );
    if ( hostParts.length === 3 ) {
        return '';
    }
    return hostParts[ 0 ] + '.';
}

export default App;
